/** @jsx jsx */
import { css } from '@emotion/react'

export const contatosHome = css` 
  background: #ffffff;
  padding: 72px 0;
  font-family: "normalidad-variable", sans-serif;
  #formulario{
    scroll-margin-top: 224px;
  }
`

export const formVisitas = css` 
  background: #4BE66A;
  border-radius: 8px;
  padding: 42px;
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #00854E;
    margin-bottom: 26px;
    font-variation-settings: "wght" 130, "wght" 600;
    width: 100%;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #00854E;
    margin-bottom: 24px;
  }
  form {
    font-family: "normalidad-variable", sans-serif;
    input {
      border: 2px solid #3b864f;
      width: 100%;
      height: 38px;
      background: #4BE66A;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #3b864f !important;
      padding-left: 24px;
      padding-right: 24px;
      &::placeholder {
        color: #3b864f;
      }
    }
    
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 24px;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-bottom: 24px;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    margin-bottom: 24px;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`

export const btnEnviar = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  font-variation-settings: "wght" 700;
  background: #00854E;
  width: 118px;
  height: 52px;
  border: none;
  margin-top: 65px;
  :hover {
    color: #ffffff;
    background: #312E2D;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: auto;
  }
`

export const formContatos = css` 
  background: #00854E;
  border-radius: 8px;
  padding: 42px;
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #4BE66A;
    margin-bottom: 26px;
    font-variation-settings: "wght" 130, "wght" 600;
    width: 100%;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #4BE66A;
    margin-bottom: 24px;
  }
  form {
    font-family: "normalidad-variable", sans-serif;
    input {
      border: 2px solid #4BE66A;
      width: 100%;
      height: 38px;
      background: #00854E;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #4BE66A ;
      padding-left: 24px;
      padding-right: 24px;
      &::placeholder {
        color: #ffffff;
      }
    }
    
    textarea {
      width: 100%;
      background: #00854E;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #4BE66A;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 10px;
      margin-bottom: 13px;
      border: 2px solid #4BE66A;
      &::placeholder {
        color: #ffffff;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`

export const btnEnviar2 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #312E2D;
  font-variation-settings: "wght" 700;
  background: #4BE66A;
  width: 118px;
  height: 52px;
  border: none;
  :hover {
    color: #ffffff;
    background: #00854E;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
  }
`
