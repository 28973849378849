/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import MaskInput from 'react-awesome-mask-input';

import {
  contatosHome,
  formVisitas,
  formContatos,
  btnEnviar,
  btnEnviar2,
} from '../assets/styles/ContatosHome.styles'

const ContatosHome = () => {
  return (
    <section css={contatosHome}>
      <Container id='formulario'>
        <Row>
          <Col lg={1} md={1} sm={12} />
          <Col
            lg={5}
            md={5}
            sm={12}
          >
            <div css={formVisitas}>
              <h2>Visitas</h2>
              <p>
                Vamos conversar. Agende abaixo o melhor dia e horário para uma
                visita.
              </p>
              <form
                name='agendar-visita'
                method='POST'
                action='/sucesso'
                data-netlify='true'
              >
                <input
                  type='hidden'
                  name='form-name'
                  value='agendar-visita'
                />
                <p>
                  <input 
                    type='text' 
                    name='nome' 
                    placeholder='Nome' 
                    required 
                  />
                </p>
                <p>
                  <MaskInput
                    type="text"
                    mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                    placeholder="Telefone"
                    name='telefone'
                  />
                </p>
                <p>
                  <input
                    type='datetime-local'
                    name='data-visita'
                    placeholder='Escolha uma hora'
                    required
                  />
                </p>
                <p>
                  <input
                    type='text'
                    name='idade filhos'
                    placeholder='Idade dos filhos'
                    required
                  />
                </p>
                <button css={btnEnviar} type='submit'>
                  Enviar
                </button>
              </form>
            </div>
          </Col>
          <Col
            lg={5}
            md={5}
            sm={12}
          >
            <div css={formContatos}>
              <h2>Contato</h2>
              <p>
                Fale com nosso time de relacionamento se quiser maiores
                informações.
              </p>
              <form
                name='contatos'
                method='POST'
                action='/sucesso'
                data-netlify='true'
              >
                <input
                  type='hidden'
                  name='form-name'
                  value='contatos'
                />
                <p>
                  <input 
                    type='text' 
                    name='name' 
                    placeholder='Nome' 
                    required 
                  />
                </p>
                <p>
                  <MaskInput
                    type="text"
                    mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                    placeholder="Telefone"
                    name='telefone'
                  />
                </p>
                <p>
                  <input
                    type='mail'
                    name='email'
                    placeholder='E-mail'
                    required
                  />
                </p>
                <textarea
                  name='mensagem'
                  rows='4'
                  cols='50'
                  placeholder='Mensagem'
                />
                <button css={btnEnviar2} type='submit'>
                  Enviar
                </button>
              </form>
            </div>
          </Col>
          <Col lg={1} md={1} sm={12} />
        </Row>
      </Container>
    </section>
  )
}

export default ContatosHome
