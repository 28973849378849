/** @jsx jsx */
import { css } from "@emotion/react";

export const ElosContent = css`
  background: #ffffff;
  overflow: hidden;
  position: relative;
  height: auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 80px;  
  @media (min-width: 320px) and (max-width: 767px) {
    height: 100%;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    height: 100%;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    height: 100%;
  }
`

export const imgBox = css`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  /* margin: auto 0; */
  width: 50%;
  z-index: 10;
  div h2 {
    color: #fff;
  }
  img {
    width: 100%;
    height: 100%;
  }
`

export const imgBoxL = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  /* margin: auto 0; */
  width: 55.5vw;
  /* height: 80vh; */
  z-index: 10;
  height: 591px;
  div h2 {
    color: #fff;
  }
  img {
    object-fit: cover;
    object-position: top;
    width: 90%;
    height: 100%;
    @media (max-width: 500px){
    }
  
  }
 
`

export const boxContent = css`
  /* padding-left: 90px; */
  height: 500px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    width: 100%;
    max-width: 475px;
  }
  h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
    margin-bottom: 40px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }
`

export const boxContentL = css`
  padding-left: 110px;
  padding-right: 20px;
  height: 591px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    width: 100%;
    /* max-width: 475px; */
    @media (min-width: 500px)  {
    padding: 0px;
   
    }

  }
  h3 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
    margin-bottom: 40px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-left: 0px;
    height: auto;
    padding-right: 0px;
    padding-bottom: 0px;
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }
  @media (max-width: 500px){
    padding-top: 30px;
    height: auto;
  }
  .accordion__panel {
    padding: 0px 18px 30px 0px;
    @media (max-width: 500px)  {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .accordion__button {
    padding: 32px 0px 32px 0px;

    @media (max-width: 500px)  {
      padding: 32px 0px 32px 0px;
      font-size: 31px !important;
    }
  }
`
export const cssMobile = css`
 width: 100%;
 @media (max-width: 500px){

   .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
      padding-right: 0px;
      padding-left: 0px;
  }
 }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`
