/** @jsx jsx */
import { css } from '@emotion/react'
import ImgPreVestibular from '../images/lp/pre-vestibular.png'
import ImgPreVestibularHover from '../images/lp/pre-vestibular-hover.png'
import ImgFundamental from '../images/lp/fundamental.png'
import ImgFundamentalHover from '../images/lp/fundamental-hover.png'
import ImgMedio from '../images/lp/medio.png'
import ImgMedioHover from '../images/lp/medio-hover.png'
import ImgJdEncantado from '../images/lp/home/jd-encantado.png'
import ImgMedianeira from '../images/lp/home/unidade-centro.png'
import ImgBoulevard from '../images/lp/home/boulevard.png'


export const EstruturaHomeContent = css`
  padding: 60px 0px;
  margin-top: 80px;
  font-family: "normalidad-variable", sans-serif;
  background-color: #F9F9F9;
  overflow: hidden;
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }

   @media (min-width: 768px) and (max-width: 990px) {
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }
  @media (max-width: 500px){
    margin-top: 0px;
    padding-top: 0px;
  }
  /*@media (min-width: 991px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
  } */
`

export const headerContent = css`
  font-family: "normalidad-variable", sans-serif;
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    margin-bottom: 20px;
    font-variation-settings: "wght" 130, "wght" 600;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    padding-bottom: 32px;
    text-align: center;
    width: 100%;
    
  }
`

export const cardPreVestibular = css`
  background: url(${ImgPreVestibular}) #4BE66A no-repeat center center / cover;
  border-radius: 8px;
  padding-top: 34px;
  padding-left: 40px;
  padding-bottom: 34px;
  transition: ease-in;
  margin-bottom: 8px;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-variation-settings: "wght" 130, "wght" 700;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #312E2D;
    width: 100%;
    text-align: left;
    max-width: 245px;
    margin-bottom: 20px;
  }
  a {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #312E2D;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #00854E;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
    }
  }
  :hover {
    background: url(${ImgPreVestibularHover}) #003770 no-repeat center center / cover;
  }
`

export const cardFundamental = css`
  background: url(${ImgFundamental}) #4BE66A no-repeat center center / cover;
  border-radius: 8px;
  padding-top: 34px;
  padding-left: 40px;
  padding-bottom: 34px;
  transition: ease-in;
  height: 309px;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-variation-settings: "wght" 130, "wght" 700;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #312E2D;
    width: 100%;
    text-align: left;
    max-width: 245px;
    margin-bottom: 20px;
  }
  a {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #312E2D;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #00854E;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
    }
  }
  :hover {
    background: url(${ImgFundamentalHover}) #003770 no-repeat center center / cover;
  }
`

export const cardMedio = css`
  background: url(${ImgMedio}) #4BE66A;
  border-radius: 8px;
  padding-top: 34px;
  padding-left: 40px;
  padding-bottom: 34px;
  transition: ease-in;
  height: 100%;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-variation-settings: "wght" 130, "wght" 700;
    margin-top: 315px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #312E2D;
    width: 100%;
    text-align: left;
    max-width: 245px;
    margin-bottom: 20px;
  }
  a {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #312E2D;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #00854E;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
    }
  }
  :hover {
    background: url(${ImgMedioHover});
  }
  @media (min-width: 320px) and (max-width: 767px) {
   margin-top: 8px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: 8px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: 8px;
  }
`

export const btnSaibaMais = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #312E2D;
  font-variation-settings: "wght" 700;
  span {
    margin-right: 8px;
  }
  :hover {
    color: #ffffff;
    text-decoration: none;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
    text-decoration: none;
  }
`

export const pdL = css`
  padding-left: 4px !important;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px !important;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    padding-left: 15px !important;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-left: 15px !important;
  }
`

export const pdR = css`
  padding-right: 4px !important;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px !important;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    padding-right: 15px !important;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-right: 15px !important;
  }
`

export const UnidadeMedianeira = css`
  a {
    background: url(${ImgMedianeira}) no-repeat center center / cover;
    border-radius: 8px;
    transition: ease-in;
    height: 320px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    font-variation-settings: "wght" 700;
    img {
      margin-left: 8px;
    }
    span {
      margin-right: 8px;
    }
    :hover {
      color: #ffffff;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
      text-decoration: none;
    }
  }
`

export const UnidadeJdEncantado = css`
  a {
    background: url(${ImgJdEncantado}) no-repeat center center / cover;
    border-radius: 8px;
    transition: ease-in;
    height: 320px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    font-variation-settings: "wght" 700;
    margin-bottom: 8px;
    img {
      margin-left: 8px;
    }
    span {
      margin-right: 8px;
    }
    :hover {
      color: #ffffff;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
      text-decoration: none;
    }
  }
`

export const UnidadeBoulevard = css`
  a {
    background: url(${ImgBoulevard}) no-repeat center center / cover;
    border-radius: 8px;
    transition: ease-in;
    height: 320px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    font-variation-settings: "wght" 700;
    img {
      margin-left: 8px;
    }
    span {
      margin-right: 8px;
    }
    :hover {
      color: #ffffff;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
      text-decoration: none;
    }
  }
`