/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import ArrowUp from '../assets/images/lp/home/arrow-w.svg'

import {
  EstruturaHomeContent,
  headerContent,
  UnidadeMedianeira,
  UnidadeJdEncantado,
  UnidadeBoulevard,
  pdR,
} from '../assets/styles/EstruturaHome.styles'

const EstruturaHome = () => {
  return (
    <section css={EstruturaHomeContent}>
      <Container>
        <Row
          data-aos-offset='300'
          data-aos='fade-down'
          data-aos-easing='ease-in-out'
          css={headerContent}
        >
          <Col lg={12} md={12} sm={12}>
            <h2>Unidades</h2>
            <p>
              Descubra nossa estrutura acolhedora e inspiradora, onde cada
              ambiente foi cuidadosamente planejado para promover o melhor
              aprendizado.
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            data-aos-offset='300'
            data-aos="flip-left"
            data-aos-easing='ease-in-out'
            css={pdR}
            lg={4}
            md={4}
            sm={12}
          >
            <div css={UnidadeMedianeira}>
              <a href='/'>
                Centro, Medianeira  <img src={ArrowUp} alt='' />
              </a>
            </div>
          </Col>
          <Col
            data-aos-offset='300'
            data-aos="flip-up"
            data-aos-easing='ease-in-out'
            css={pdR}
            lg={4}
            md={4}
            sm={12}
          >
            <div css={UnidadeJdEncantado}>
              <a href='/'>
                Jardim Encantado, Medianeira <img src={ArrowUp} alt='' />
              </a>
            </div>
          </Col>
          <Col
            data-aos-offset='300'
            data-aos='flip-right'
            data-aos-easing='ease-in-out'
            css={pdR}
            lg={4}
            md={4}
            sm={12}
          >
            <div css={UnidadeBoulevard}>
              <a href='/'>
                Boulevard, Foz do Iguaçu  <img src={ArrowUp} alt='' />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EstruturaHome
