/** @jsx jsx */
import { css } from "@emotion/react";

export const container = css`
    height: auto;
    background-color: #F5F5F5;
`

export const titlesParagraphs = css`
    max-width: 890px;
    margin: 0 auto;
    h1{
        color: #312F2D;
        text-align: center;
        font-family: "Normalidad VF", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        padding-top: 80px;
        padding-bottom: 16px;
    }
    p{
        text-align: center;
        color: #312E2D;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; 
        padding-bottom: 30px;
    }
    .col{
        display: flex;
        flex-direction: column; 
        justify-content: center; 
        align-items: center;     
        text-align: center; 
        height: 100%;   
    }
`
export const segments = css`
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 80px;
    @media (max-width: 500px){
        flex-direction: column;
    }
    h1{
        color:  #312E2D;
        font-family: "Normalidad VF", sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%; 
        padding-top: 20px;
        padding-bottom: 10px;
    }
    a{
        text-decoration: none;
        color: inherit;
        color:  #312E2D;
        font-family: "Normalidad VF",sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
 
            & img{
                 transition: filter 0.3s ease-out;
            }
            :hover {
                color: #4BE66A;
                img {
                    filter: brightness(0) saturate(100%) invert(94%) sepia(27%) saturate(1948%) hue-rotate(56deg) brightness(105%) contrast(80%);
                }
            }
            :active {
                color:#00854E;
                img {
                    filter: brightness(0) saturate(100%) invert(21%) sepia(94%) saturate(3897%) hue-rotate(154deg) brightness(95%) contrast(101%);
                }
            }
            @media (max-width: 500px){
                padding-bottom: 50px;
            }
    }
    .colSegments{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
    }
    .teens{
        width: 90%;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        @media (max-width: 500px){
            width: 85%;
            height: auto; 
        }
    }
    .arroup{
        width: 15px;
        height: 15px;
    }
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        position: relative;
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }
   
`
 export const rowSegments = css`
  .row {
        margin-right: 40px;
        margin-left: 40px;
    }
 `