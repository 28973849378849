/** @jsx jsx */
import { Link } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'
import { container, containerSegments, rowSegments, segments, titlesParagraphs } from '../assets/styles/Segments.styles'
import { Fragment } from 'react'
import { jsx } from '@emotion/react'
import { segmentsData } from '@/data'
import arrow from '../assets/images/lp/icons/arrow-up.svg'


const Segments = () => {
  return (
    <Fragment >
      <section css={container}>
        <Container>
        <Row  css={titlesParagraphs}>
            <Col className='col' lg={12} md={12} sm={12}>
              <div>
                <h1>Segmentos</h1>
                <p>Conheça nossa proposta pedagógica para a Educação Infantil, Ensino Fundamental I e II, Ensino Médio e Pré-vestibular, desenvolvida para atender às necessidades e potenciais de cada estudante.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container css={rowSegments}  fluid>
          <Row lg={12} md={12} sm={12} css={segments}>
            {segmentsData.map(({image,title,link},index) => {
              return (
                <Col className='colSegments' key={index}>
                  <img className='teens' src={image} alt='garota'/>
                  <h1>{title}</h1>
                  <Link to='#'>
                    <span>{link} <img className='arroup' src={arrow} alt='jovens'/></span>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default Segments
